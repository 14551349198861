<template>
  <!-- <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :mini-variant="miniVariant"
    :src="barImage"
    app
  >-->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :mini-variant="miniVariant"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-0" />

    <v-list dense>
      <v-list-item>
        <v-list-item-avatar class="mx-auto text-center" color="white" size="100px">
          <v-img :src="companyData.logo" contain></v-img>
        </v-list-item-avatar>
      </v-list-item>


      <v-list-item class="text-center" two-line :class="miniVariant && 'px-0'">
        <v-list-item-content>
          <v-list-item-title class="text-wrap pb-1">{{companyData.companyVisualName}}</v-list-item-title>
          <v-list-item-subtitle>{{companyData.branchOfficeVisualName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item two-line :class="miniVariant && 'px-0'">
        <v-list-item-avatar class="align-self-center" color="white">
          <v-img
            src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1">Company</v-list-item-title>
        </v-list-item-content>
      </v-list-item>-->

      <v-divider class="mb-2" />

      <template v-for="item in availableItems">
        <v-row v-if="item.heading" :key="item.heading" no-gutters>
          <v-col cols="12">
            <v-subheader>{{ item.heading }}</v-subheader>
            <v-divider class="mb-2" />
          </v-col>
        </v-row>

        <v-list-group
          v-else-if="item.children"
          :key="item.text"
           v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(child, i) in item.children" :key="i" exact :to="child.to" class="pl-11">
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ child.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="item.text" exact :to="item.to">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="text-center white--text mb-3">
        Versión: {{$Const.APP_VERSION}}
      </div>
			
		</template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "GavcomCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    },
    miniVariant: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    availableItems: [],
    items: [
      { roles:["*"], icon: "mdi-home-analytics", text: "Inicio", to: { name: 'Home'} },
      {
        roles:["Admin"],
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Accesos",
        model: false,
        children: [
          { roles:["Admin"], icon: "mdi-home-city", text: "Sucursales", to: { name: 'Sucursales'} },
          { roles:["Admin"], icon: "mdi-shield-account", text: "Roles", to: { name: 'Roles'} },
          { roles:["Admin"], icon: "mdi-account-group", text: "Usuarios", to: { name: 'Usuarios'} }
        ]
      },
      {
        roles:["Admin", "Prod", "Inv"],
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Almacén",
        model: false,
        children: [
          { roles:["Admin", "Prod"], icon: "mdi-clipboard-text", text: "Categorías", to: { name: 'Categorías'} },
          { roles:["Admin", "Prod"],  icon: "mdi-cart", text: "Productos", to: { name: 'Productos'} },
          { roles:["Admin", "Inv"],  icon: "mdi-chart-bar ", text: "Inventario", to: { name: 'Inventario'} }
        ]
      },
      {
        roles:["Admin", "Prov", "Comp", "CredComp"], 
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Compras",
        model: false,
        children: [
          { roles:["Admin", "Comp"], icon: "mdi-clipboard-check", text: "Orden de compra", to: { name: 'Compras'} },
          { roles:["Admin", "CredComp"], icon: "mdi-cash-minus", text: "Créditos (Compras)", to: { name: 'CreditosCompras'} },
          { roles:["Admin", "Prov"], icon: "mdi-van-utility", text: "Proveedores", to: { name: 'Proveedores'} }
        ]
      },
      {
        roles:["Admin", "Cli", "Vent", "CredVent"], 
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Ventas",
        model: false,
        children: [
          { roles:["Admin", "Vent"], icon: "mdi-trending-up", text: "Punto de venta", to: { name: 'Ventas'} },
          { roles:["Admin", "CredVent"], icon: "mdi-cash-plus", text: "Créditos (Ventas)", to: { name: 'CreditosVentas'} },
          { roles:["Admin", "Cli"], icon: "mdi-account-multiple-check", text: "Clientes", to: { name: 'Clientes'} }
        ]
      },
      {
        roles:["Admin", "Repo"], 
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Reportes",
        model: false,
        children: [
          { roles:["Admin", "Repo"], icon: "mdi-file-chart", text: "Reporte de inventario", to: { name: 'ReporteInventario'} },
          { roles:["Admin", "Repo"], icon: "mdi-file-table", text: "Reporte de compras", to: { name: 'ReporteCompras'} },
          { roles:["Admin", "Repo"], icon: "mdi-finance", text: "Reporte de ventas", to: { name: 'ReporteVentas'} }
        ]
      },
      // {
      //   roles:["Admin", "Cont"], 
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "Contabilidad",
      //   model: false,
      //   children: [
      //     { icon: "mdi-content-copy", text: "Libro diario", to: "" },
      //     { icon: "mdi-content-copy", text: "Libro mayor", to: "" },
      //     { icon: "mdi-content-copy", text: "Balance general", to: "" }
      //   ]
      // }
    ]
  }),

  computed: {
    ...mapState(["barColor", "barImage", "companyData", "authData"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    },
  },

  created() {
    this.availableItems = this.getAvailableItems(this.items)
    this.setActiveGroup(this.$route.name)
  },

  watch: {
    $route(to, from) {
      if (to.matched.length > 1) {
        this.setActiveGroup(to.name)
      }
    }
  },


  methods: {
    setActiveGroup(name){
      var me = this;
      // retain the current route from this.$route.name or path and set it to current variable
      var current = name; // set from this.$route
      if (current) {
        if (current === 'Home') {
          me.availableItems.forEach(item => {
            if (item.children && item.children.length) {
              item.model = false
            }
          })
        } else {
          me.availableItems.forEach((item, i) => {
            if (item.children && item.children.length) {
              if (item.children.map(x => x.to.name).includes(current)) {
                var index = me.items.indexOf(item)
                me.items[index].model = true;
              }
            }
          })
        }
      }
    },

    getAvailableItems(items){
      if (!items) {
        return;
      }

      var childrenFound=[]
      const custom = this.items.filter(item => item.roles.some(role => role === '*') && !!item.to)
      custom.map(c => childrenFound.push({icon: c.icon, text: c.text, to: c.to}))

      const result = this.items.filter(item => {
        var aux = item.roles.some(role => role === '*' || this.authData.roles.includes(role))
        if (aux && item.children) {
          item.children = item.children.filter(child => {
            return child.roles.some(childRole => this.authData.roles.includes(childRole))
          })
          item.children.map(child => childrenFound.push({icon: child.icon, text: child.text, to: child.to}))
        }
        return aux
      });

      this.$store.commit("SET_AVAILABLE_MENU", childrenFound);
      return result;
    },
  },
};
</script>